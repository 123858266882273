.bg-color-login{
	background-color: #0c3149;
}

.TexAreahorizontal {
	resize: vertical;
	max-height: 130px;
	min-height: 100px;
  }
.ButtonEmpresa {
	text-align: left;
}
  @media (max-width: 1023px) {
	.ButtonEmpresa{ 
	text-align: center;
}
}
