@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.map-container {
	height: 400px;
}

.OpacityBannerProfile{
	opacity: 0.9;
}

.react-multi-carousel-list{
    padding-bottom: 40px !important;
}

.custom-dot-list-style button{
    background: grey;
}

.react-multi-carousel-dot--active button{
    background: white !important;
}

@media (max-width: 762px) {
	.containerPerfilEmp{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 10px;
	};
  }
@media (max-width: 767px) {

	.containerInfoProfile{
		bottom: -5rem !important;
	}

	.containermarginProfileEmployer{
		margin-top:13px;
	}

	.flexContinaerProfileEmployer{
		display: flex;
		justify-content: center;
		padding-top: 4px;
	}

	/* sm:flex sm:justify-center sm:pt-4 md:pt-0 */



  }

  

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(239, 146, 24, 1) rgba(9, 15, 44 , 1);
    scroll-behavior: smooth;
}

.btn-close{
	height: auto;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
    @apply w-3 h-3 transition;
}

::-webkit-scrollbar-button {
	@apply w-0 h-0;
}

::-webkit-scrollbar-thumb {
	@apply bg-Yellow border-0 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
	@apply bg-dark-yellow;
}

::-webkit-scrollbar-thumb:active {
	@apply bg-dark-yellow;
}

::-webkit-scrollbar-track {
	@apply bg-turquoise border-0 rounded-none;
}

::-webkit-scrollbar-track:hover {
	@apply bg-turquoise;
}

::-webkit-scrollbar-track:active {
	@apply bg-turquoise;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

html, body {
	@apply font-roboto;
	scroll-behavior: smooth;
}

.first-circle{
    animation-delay: 0.1s !important;
}
.second-circle{
    animation-delay: 0.2s !important;
}
.third-circle{
    animation-delay: 0.3s !important;
}



input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

@media only screen and (max-width: 1050px) and (min-width: 1024px) {
	.tamañoContenedorImg{
		width: auto;
	}

	.tamañoImg{
		width: auto;
	}
}

.bg-employeer {
	@apply bg-fondoPortada bg-cover;
	background-position-y: center;
}



html, body {
	height: 100%;
	margin: 0;
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content {
	flex: 1;
}

/* Css de DataTable */
.data-table-extensions {
    @apply py-5 pb-5;
}

.data-table-extensions .data-table-extensions-filter .filter-text {
    @apply bg-gray-50 border border-gray-300 transition-all text-gray-900 sm:text-sm rounded focus:ring-cyan-600 focus:border-cyan-600 block w-full px-2 py-1 max-w-xs outline-none;
}

.rdt_Table .rdt_TableBody .rdt_TableRow {
    @apply text-sm text-gray-900;
}

div.flex.flex-wrap.items-center.px-5 > div {
	@apply w-full !important;
}

.data-table-extensions {
    @apply pb-5;
}

div.data-table-extensions-filter > .filter-text {
    @apply text-sm rounded block w-full p-2.5 text-gray-500 bg-white !important;
}

.rdt_Table .rdt_TableHeadRow {
    /* background: rgba(30, 58, 138, 1); */
    @apply text-white text-xs font-medium uppercase bg-Naranja-Ticongle w-full !important;
} 

.kwoXWb {
	@apply text-sm text-white bg-Light-purple border-b-white !important;
}

.fXiJIw {
	@apply bg-Light-purple text-white border-t-white !important;
}

.iiEFHg:disabled {
	@apply text-gray-300 fill-gray-400 !important; 
}

.iiEFHg {
	@apply text-white fill-white !important;
}

.bYfINf {
	@apply bg-Light-purple text-white !important;
}

.hNRvgW {
	@apply bg-Light-purple text-white !important;
}

.cDWPRa {
	@apply bg-Light-purple text-white !important;
}
/* Css de DataTable */