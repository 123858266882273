body{
   @apply bg-turquoise;
}

input#react-select-3-input{
 color: white !important;
}

.estilo-navbar {
    position: absolute;
    inset: auto auto 0px 0px;
    margin: 0px;
    transform: translate(1126px, 1007px);
}

/* animaciones modal */
.bg-fadeIn{
   @apply animate-fadeIn ease-in;
   animation-duration: 0.5s;
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
}

.bg-fadeOut{
   @apply animate-fadeOut ease-in;
   animation-duration: 0.5s;
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
}

.modal-scaleIn{
   @apply animate-scaleIn ease-in;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
}

.modal-scaleout{
   @apply animate-scaleOut ease-in;
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
   animation-iteration-count: 1;
}

/*Calendar input*/
input::-webkit-calendar-picker-indicator { 
   filter: contrast(40%) sepia(1) hue-rotate(20deg) saturate(6000%);
}

/*Input file*/
.btn-editpro input[type=file]::file-selector-button {
   border: 0px solid transparent;
   padding: .4em .8em;
   border-radius: .2em;
   background-color: #057DAD;
   transition: 1s;
   color: #fff;
   padding: 10px 40px 10px 40px;
   font-size: medium;
   margin-right: 20px;
   cursor: pointer;
 }
 
 .btn-editpro input[type=file]::file-selector-button:hover {
   background-color: #3f8dac;
 }

 .btn-file input[type=file]::file-selector-button {
   border: 0px solid transparent;
   padding: .4em .8em;
   border-radius: .2em;
   background-color: rgb(3 105 161);
   color: #fff;
   padding: 10px 5px 10px 5px;
   font-size: medium;
   margin-right: 20px;
   cursor: pointer;
 }
 
 .btn-file input[type=file]::file-selector-button:hover {
   background-color: rgb(2 132 199);
 }

 /*Input range*/
/*Chrome*/
 input[type='range'] {
   background: #057DAD;
   border: solid 2px transparent;
   border-radius: 8px;
   height: 10px;
   width: 100%;
   outline: none;
   transition: background 450ms ease-in;
   -webkit-appearance: none;
 }
 
 
 input[type='range']::-webkit-slider-thumb {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   -webkit-appearance: none;
   cursor: ew-resize;
   background: #FF9200;
 }

 /* Firefox */
 input[type=range]::-moz-range-thumb {
   background-color: #FF9200;
 }

 .basic-multi-select{
    width: 100%;
    color: #fff !important;
 }

 .skills-style .select__control{
  background: #241145 !important;
  border-color: #241145 !important;
  color: #fff !important;
 }

 .skills-style .select__value-container{
    background: #241145 !important;
    color: #fff !important;
  }

  .skills-style .select__menu{
    background: #241145 !important;
    color: #FF9200;
 }

 .skills-style .css-1rhbuit-multiValue{
  background: #057DAD !important;
  color: #ffffff !important;
  /* padding: 5px 10px 5px 10px; */
  font-size: 20px;
 }

 .skills-style .css-12jo7m5{
  color: #ffffff !important;
 }

 .skills-style option:hover{
    background-color: #ffffff !important;
  }

  .select-skills select:focus{
    outline: none !important;
  }
  .select-skills option:hover{
    outline: none !important;
  }
 
 .select__control{
   background: #057dad !important;
   border-color: #057dad !important;
 }

.select__value-container{
   background: #057dad !important;
}

.select__placeholder{
   color: white !important;
}


.select__menu{
   background: #057dad !important;
   color: #FF9200;
}

.select__menu > option{
   background: red;
}




@media (max-width: 640px) {
   .btn-editpro input[type=file]::file-selector-button {
      padding: 15px 15px 15px 15px;
      font-size: smaller;
   }

   .btn-file input[type=file]::file-selector-button {
      padding: 15px 15px 15px 15px;
      font-size: smaller;
   }
}

input, select, textarea{
   @apply bg-input-blue text-white focus:border-Yellow;
}

input:-webkit-autofill, 
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
select:-webkit-autofill, 
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus, 
select:-webkit-autofill:active,
textarea:-webkit-autofill, 
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
textarea:-webkit-autofill:active  
{ 
   -webkit-box-shadow: 0 0 0 30px #3e5a6c inset !important; 
   -webkit-text-fill-color: white !important;
}
/*Sweet Alert*/
/*Error*/
.swal2-icon.swal2-error {
   border-color: #ff3737 !important;
   color: #ff3737 !important;
 }

 .swal2-icon.swal2-error [class^=swal2-x-mark-line]{
   background-color: #ff3737 !important;
 }

 .swal2-title{
   color: #ffffff !important;
 }

 .swal2-popup{
   background-color: #0D021E!important;
 }



/*Select Multiple*/
.css-6j8wv5-Input{
   padding: 0.57rem 0px 0.57rem 0px !important;
   color: #fff;
}


.css-1okebmr-indicatorSeparator{
   background-color: transparent !important;
}


/* class Modal */
.modalOpenProject{
   display: block;
}

.modalCloseProject{
   display: none;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
   content: "\25B2";
   position: absolute;
   top: 5%;
   left: 45%;
   margin: auto;
   color: #FF9200;
 }
 
 .react-datepicker__year-dropdown::after {
   content: "\25BC";
   display: block;
   position: relative;
   bottom: 15%;
   color: #FF9200;
 }

 react-datepicker__year-option{
   display: none;

 }

 /* Button Loading */

 .iconLoading{
   width: 17%;
   margin-top: -6%;
   margin-bottom: -6%;
 }
 
 .iconLoadingWhite{
  width: 40%;
  margin-top: -6%;
  margin-bottom: -6%;
}

.iconLoadingModal{
  width: 25%;
  margin-top: -6%;
  margin-bottom: -6%;
}


 .show-enter {
   opacity: 0.3;
   /* transform: translateY(20%); */
 }
 .show-enter-active {
   opacity: 1;
   /* transform: translateY(0); */
   transition: opacity 300ms, transform 300ms;
 }
 .show-exit {
   opacity: 1;
 }
 .show-exit-active {
   opacity: 0.3;
   /* transform: translateY(20%); */
   transition: opacity 300ms, transform 300ms;
 }

 .lds-ring {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
 }
 .lds-ring div {
   box-sizing: border-box;
   display: block;
   position: absolute;
   width: 45px;
   height: 45px;
   margin: 8px;
   border: 8px solid #fff;
   border-radius: 50%;
   animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: #fff transparent transparent transparent;
 }
 .lds-ring div:nth-child(1) {
   animation-delay: -0.45s;
 }
 .lds-ring div:nth-child(2) {
   animation-delay: -0.3s;
 }
 .lds-ring div:nth-child(3) {
   animation-delay: -0.15s;
 }
 @keyframes lds-ring {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }

#react-select-3-listbox {
  /* background-color: #ff3737 !important; */
}

